
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./pages/NavBar";
import Content from "./components/Content";
import Questions from "./pages/Questions";
import Events from "./routes/events";

import Privacy from "./routes/faq";
import data from './data'; 
import { useTranslation } from 'react-i18next';

export default function App() {
  const { i18n } = useTranslation();
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    // Frissíti az adatokat a nyelv változásakor
    const updatedData = i18n.language === 'hu' ? data.hu : data.en;
    setAllData(updatedData);
  }, [i18n.language]);

  return (
    <>
      <Router>
  
        <Navigation data={allData} i18n={i18n} />

        <Routes>
          <Route path="*" element={<Content />} />
          <Route path="/events" element={<Events />}  />
          {/* <Route path="/ref" element={<Ref />} /> */}
          <Route path="/faq" element={<Questions data={allData} i18n={i18n}/>} />
          <Route path="/privacy" element={<Privacy data={allData} i18n={i18n}/>} />

        </Routes>
       
      </Router>
    </>
  );
}

import React from 'react'

const Cookies = ({data}) => {
    return (
        <div className="bg-black text-gray-800 font-sans leading-relaxed p-6">
        <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-md">
          <h1 className="text-3xl font-bold text-blue-600 mb-6">Adatkezelési Tájékoztató</h1>
  
          <p className="mb-4">Tisztelt Felhasználó!</p>
  
          <p className="mb-4">
            Köszönjük, hogy bizalommal fordult hozzánk. Szeretnénk biztosítani Önt arról, hogy az Ön személyes adatainak védelme kiemelten fontos számunkra. Az alábbiakban részletesen bemutatjuk adatkezelési gyakorlatunkat, hogy teljes körű tájékoztatást nyújtsunk az Ön jogairól, valamint az általunk alkalmazott adatkezelési elvekről.
          </p>
  
          <h2 className="text-2xl font-semibold text-blue-500 mt-6 mb-4">Az adatok felhasználása</h2>
          <p className="mb-4">Az Ön által megadott személyes adatokat kizárólag a szolgáltatásaink biztosítása érdekében kezeljük. Az adatokat elsősorban az alábbi célokra használjuk fel:</p>
          <ul className="list-disc list-inside mb-4">
            <li>A szolgáltatások nyújtásához szükséges kapcsolattartás biztosítása.</li>
            <li>Az ügyfélkérések feldolgozása és megválaszolása.</li>
            <li>Az esetlegesen felmerülő jogi kötelezettségeink teljesítése.</li>
          </ul>
          <p className="mb-4">
            Hangsúlyozzuk, hogy az Ön adatait semmilyen körülmények között nem osztjuk meg harmadik féllel, kivéve, ha ezt jogszabály írja elő, vagy Ön ehhez előzetesen kifejezetten hozzájárult.
          </p>
  
          <h2 className="text-2xl font-semibold text-blue-500 mt-6 mb-4">Adattárolás és törlés szabályai</h2>
          <p className="mb-4">Az adatkezelés időtartamát szigorú szabályok határozzák meg annak érdekében, hogy az Ön adatai ne kerüljenek a szükségesnél hosszabb ideig tárolásra.</p>
          <ul className="list-disc list-inside mb-4">
            <li>Ön jogosult bármikor kérni személyes adatainak törlését. Ilyen esetben az adatok kérésének beérkezésétől számított legfeljebb 30 napon belül véglegesen töröljük azokat rendszerünkből.</li>
            <li>Amennyiben Ön nem kezdeményezi adatai törlését, azok a rendszerünkben legfeljebb 2 évig kerülnek tárolásra. Az adatokat ezt követően automatikusan töröljük, biztosítva ezzel az adatmegőrzési szabályoknak való megfelelést.</li>
          </ul>
  
          <h2 className="text-2xl font-semibold text-blue-500 mt-6 mb-4">Adatbiztonság és jogorvoslati lehetőségek</h2>
          <p className="mb-4">Az Ön adatainak védelme érdekében minden technikai és szervezési intézkedést megteszünk. Adatkezelési gyakorlatunk megfelel a vonatkozó jogszabályok, így különösen az Európai Unió Általános Adatvédelmi Rendelete (GDPR) előírásainak.</p>
          <p className="mb-4">Az adatkezeléssel kapcsolatban Önnek az alábbi jogai vannak:</p>
          <ul className="list-disc list-inside mb-4">
            <li><strong>Hozzáférési jog</strong>: Ön bármikor kérhet tájékoztatást arról, hogy milyen adatokat kezelünk Önről, valamint ezen adatok kezelésének céljáról.</li>
            <li><strong>Helyesbítéshez való jog</strong>: Jogosult kérni az esetleges pontatlan vagy hiányos adatok helyesbítését.</li>
            <li><strong>Törléshez való jog</strong>: Ön kérheti személyes adatainak törlését, amelyet a fentiekben ismertetett határidőn belül teljesítünk.</li>
            <li><strong>Adatkezelés korlátozása</strong>: Bizonyos esetekben kérheti az adatok kezelésének korlátozását.</li>
            <li><strong>Tiltakozás az adatkezelés ellen</strong>: Ön bármikor tiltakozhat adatai kezelése ellen, különösen, ha az adatkezelés közvetlen üzletszerzés céljából történik.</li>
          </ul>
  
          <h2 className="text-2xl font-semibold text-blue-500 mt-6 mb-4">Kapcsolatfelvétel és további információ</h2>
          <p className="mb-4">
            Ha bármilyen kérdése merülne fel adatkezelési gyakorlatunkkal kapcsolatban, vagy élni szeretne valamely jogával, kérjük, forduljon hozzánk bizalommal az alábbi elérhetőségek valamelyikén:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li><strong>Név: </strong>Loretta Lakatos</li>
            <li><strong>Székhely: </strong>2112 Veresegyház, Szablya utca 18.</li>
            <li><strong>E-mail cím</strong>: <a href="lorettalkts@gmail.com" className="text-blue-600 hover:underline">lorettalkts@gmail.com</a></li>
            <li><strong>Telefonszám</strong>: <a href="tel:+36304804374" className="text-blue-600 hover:underline">+36 30 480 4374</a></li>
            <li><strong>Weboldal megnevezése, címe: </strong>www.hotgirlydanceclass.com</li>
          </ul>
  
          <p className="mt-6">Köszönjük együttműködését és bizalmát!</p>
        </div>
      </div>
    );
  };

export default Cookies

import React, { useState, useEffect } from 'react';
import data from '../data'; 
import { useTranslation } from 'react-i18next';


import Privacy from '../pages/Privacy'
import { Footer } from '../pages/Footer'


const Faq = () => {
    const { i18n } = useTranslation();
    const [allData, setAllData] = useState([]);
  
    useEffect(() => {
      if (i18n.language === 'hu') {
        setAllData(data.hu);
      } else {
        setAllData(data.en);
      }
    }, [i18n.language]);
  
    return (
        <>
          
           <Privacy data={allData} i18n={i18n} />
           <Footer data={allData} i18n={i18n}/>
         
        </>
    )
}

export default Faq
import React, { useState } from 'react';
import { ReactComponent as GlobeIcon } from '../assets/globe.svg';
import { ReactComponent as USFlagIcon } from '../assets/uk-flag.svg';
import { ReactComponent as HUFlagIcon } from '../assets/hu-flag.svg';

export default function LanguageBtn({ i18n }) {
  const [showLangOptions, setShowLangOptions] = useState(false);

  const toggleLangOptions = () => {
    setShowLangOptions(!showLangOptions);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setShowLangOptions(false);
  };

  return (
    <div className="relative inline-block pt-4">
      <button
        className="focus:outline-none"
        onClick={toggleLangOptions}
        aria-label="Language Options"
      >
        <GlobeIcon className="w-6 h-6" />
      </button>
      {showLangOptions && (
        <div className="absolute z-10 top-10 right-0  rounded-md shadow-lg">
          <button
            className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-100"
            onClick={() => changeLanguage('en')}
          >
            <span className="inline-block w-8 h-6 mr-2">   
              <USFlagIcon className=" w-full h-full" /> 
            </span>
          
          
          </button>
          <button
            className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-100"
            onClick={() => changeLanguage('hu')}
          >
            <span className="inline-block w-8 h-6 mr-2">
              <HUFlagIcon className=" w-full h-full" />
            </span>
            
           
          </button>
        </div>
      )}
    </div>
  );
}

import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Trainings({ data }) {
  return (
    <div name="trainings" className="bg-black w-full h-full p-5 relative min-h-screen flex flex-col items-center justify-center">
      <p className="text-5xl text-white teachers-heading mb-0 hover:text-gray-500 active:text-gray-500">
        <RouterLink to="/faq"> {data.nav3} </RouterLink>
      </p>
      <h4 className=" text-white mb-6">{data.training1}</h4>
      <Swiper
        spaceBetween={20}
        slidesPerView={2}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
          bulletClass: "custom-swiper-pagination-bullet",
          bulletActiveClass: "custom-swiper-pagination-bullet-active",
        }}
        modules={[Autoplay, Pagination]}
        className="w-full max-w-4xl relative"
      >
        {[
          "kezdo",
          "közephalado",
          "halado",
          "open1"
        ].map((level, index) => (
          <SwiperSlide key={level}>
            <div className="flex items-center justify-center">
              <div className="relative bg-black w-full group max-w-md mx-auto p-6 border border-gray-300 shadow-2xl rounded-xl h-96">
                <div className="text-center overflow-y-auto h-full scrollbar">
                  <h3 className="mb-4 text-2xl font-bold text-gray-200">{data[level]}</h3>
                  <p className="mb-4 font-light text-gray-300">
                    {data[level === "kezdo" ? "basic" : level === "közephalado" ? "startup" : level === "halado" ? "motivated" : "open"]}
                  </p>
                  <RouterLink to={`/tanarok/${index + 1}`} className="text-red-500 hover:underline">
                    {data.nav2} →
                  </RouterLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-pagination flex justify-center mt-4"></div>
    </div>
  );
}

// Custom CSS for Swiper pagination bullets
const customSwiperStyles = `
.custom-swiper-pagination-bullet {
  background: #ffffff;
  width: 12px;
  height: 12px;
  opacity: 1;
  border-radius: 50%;
  margin: 0 4px;
}

.custom-swiper-pagination-bullet-active {
  background: #ff0000;
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = customSwiperStyles;
document.head.appendChild(styleSheet);

import React, { useState, useEffect } from 'react';
import Header from "../pages/Header";
import Teachers from "../pages/Teachers";
import Calendar from "../pages/Schedule/calendarStyle";
import Trainings from "../pages/Trainings";
import Contact from "../pages/Contact";
import {Footer} from "../pages/Footer";
import ScrollButton from "../pages/ScrollButton"
import Sponsor from "../pages/Sponsor";
import data from '../data'; 
import { useTranslation } from 'react-i18next';
import Newsletter from '../pages/Newsletter';
import Places from '../pages/Places';



const Content = () => {
  const { i18n } = useTranslation();
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    if (i18n.language === 'hu') {
      setAllData(data.hu);
    } else {
      setAllData(data.en);
    }
  }, [i18n.language]);

  return (
    <div id="mainContent">
      
      <Header data={allData} i18n={i18n} />
      <Teachers data={allData} i18n={i18n} />
      <Calendar data={allData} i18n={i18n} />
      <Trainings data={allData} i18n={i18n} />
      <Places data={allData} i18n={i18n} />
      <Contact data={allData} i18n={i18n} />
      <Sponsor/>
      <Newsletter data={allData} i18n={i18n}/>
      <Footer data={allData} i18n={i18n}/>
      <ScrollButton/>
    </div>
  );
};
export default Content;

import React from "react";
import Open from "../assets/open.jpg";

const EventBoard = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-black pt-20">
      <div className="max-w-lg mx-auto overflow-hidden rounded-lg">
       
        <img
          className="w-full h-auto"
          src={Open}
          alt="Open Week"
        />
        <div className="bg-white p-4">
          <h2 className="text-2xl font-bold mb-2">Kedves CsodaNőink🥰</h2>
          <p className="text-gray-700 mb-4">
            Idèn is megrendezzük HOT GIRLY OPEN WEEK-ünket, melyen kedvezményesen próbàlhatjàtok ki magatokat, az összes helyszínünkön.❤️‍🔥🔥
          </p>
          <h3 className="text-xl font-semibold mb-2">NYÍLT HÉT: FEBRUÁR 3–7.</h3>
          <p className="text-gray-700 mb-4">
            Ezen a héten az új tagok kedvezményesen próbálhatják ki óráinkat! A bérletesek bérlettel tudnak majd részt venni!
          </p>
          <ul className="list-disc list-inside mb-4">
            <li className="text-gray-700">Óradíjak:</li>
            <li className="text-gray-700">60 perces óra: 1.500 Ft</li>
            <li className="text-gray-700">90 perces óra: 2.500 Ft</li>
          </ul>
          <p className="text-gray-700 mb-4">Bérletvásárlás és képzések kezdete: FEBRUÁR 10-től</p>
          <h3 className="text-xl font-semibold mb-2">HOT GIRLY NYÍLT HÉT ÓRAREND</h3>
          <ul className="list-disc list-inside mb-4">
            <li className="text-gray-700">📆FEBRUÁR 3. – HÉTFŐ</li>
            <li className="text-gray-700">📌SZIKLAI LILI & VARGA BIANKA - HÈTFŐ 18:00-19:00 HOT START UP kurzus - Helyszín: HOT STUDIO - TELJESEN KEZDŐ</li>
            <li className="text-gray-700">📌LAKATOS LORETTA - HÉTFŐ 19:00-20:00 HOT MOTIVATED BASIC - Helyszín: HOT STUDIO - KÖZÉPHALADÓ</li>
            <li className="text-gray-700">📌LAKATOS LORETTA - HÉTFŐ 20:00-21:30 HOT MOTIVATED STAGE - Helyszín: HOT STUDIO - HALADÓ</li>
            {/* Add további órarend részletek itt */}
          </ul>
          <p className="text-gray-700">
            ➡️ JELENTKEZÉS: Erősítsd meg részvételedet az esemény Facebook oldalán, küldj e-mailt, vagy keresd az oktatókat privát üzenetben.️
          </p>
          <p className="text-gray-700">
            ❓ Gyakran Ismételt Kérdések: Hozz magaddal sportcipőt, magassarkút, vizet, térdvédőt, kényelmes és magabiztos öltözetet. Cipő, ami tartja a bokádat.
          </p>
          <p className="text-gray-700">Várunk minden csatlakozni vágyót!</p>
          <p className="text-gray-700 font-semibold">HOT GIRLY TEAM💋</p>
        </div>
      </div>
    </div>
  );
};

export default EventBoard;

import React, { useState } from "react";
import Back from "../assets/IMG_0504.jpeg";

export default function Questions({ data }) {
    const [openBox, setOpenBox] = useState(Array(8).fill(false));

    return (
        <div name="questions" className="w-screen h-screen relative pt-24"> 
            <div
                className="absolute inset-0 bg-cover bg-center z-0"
                style={{ backgroundImage: `url(${Back})` }}
            ></div>
            <div className="relative flex flex-col items-center justify-center h-full overflow-auto px-4">
                <div className="py-8 text-center">
                    <h1 className="xl:text-6xl md:text-5xl sm:text-2xl font-bold text-black">
                        {data.question}
                    </h1>
                </div>
                <div className="lg:w-1/2 md:w-8/12 sm:w-11/12 w-full">
                    {Array(8).fill().map((_, i) => (
                        <div key={i} className="bg-white shadow rounded p-6 mt-6">
                            <div className="flex items-center justify-between">
                                <h2 className="text-base font-semibold text-gray-800">
                                    {data[`question${i + 1}`]}
                                </h2>
                                <button
                                    onClick={() => setOpenBox(prev => prev.map((v, idx) => idx === i ? !v : v))}
                                    className="focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                >
                                    {openBox[i] ? "▲" : "▼"}
                                </button>
                            </div>
                            {openBox[i] && (
                                <p className="text-base text-gray-600 mt-4">
                                    {data[`answer${i + 1}`]}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

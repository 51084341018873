import React, { useState, useEffect } from "react";
import Petnehazy from "../assets/petnehazy.png";
import Székesfehervar from "../assets/fehervar.jpg";
import Lili from "../assets/Lili.jpeg";
import Lola from "../assets/Lola.jpeg";
import Vilma from "../assets/Vilma.jpeg";
import Niki from "../assets/Niki.jpeg";
import Netti from "../assets/Netti.jpeg";
import Flóra from "../assets/Flóra.jpeg";
import Lilla from "../assets/Lilla.jpeg";

const schedule = [
  { title: "Lili", place: "Petneházy u. 81. 1. emelet", name: "", pic: Lili },
  { title: "Lili", place: "Telepes utca 51 - Táncterem Zugló", name: "", pic: Lili },
  { title: "Lola", place: "Petneházy u. 81. 1. emelet", name: "", pic: Lola },
  { title: "Vilma", place: "Petneházy u. 81. 1. emelet", name: "", pic: Vilma },
  { title: "Niki", place: "Szépvölgyi út 44. La Place Studio", name: "", pic: Niki },
  { title: "Netti", place: "Ipar utca 3. Performance Studio", name: "", pic: Netti },
  { title: "Flóra", place: "Székesfehérvár, Széchenyi út 122.", name: "", pic: Flóra },
  { title: "Flóra", place: "Dohány utca 5/b Astoria", name: "", pic: Flóra },
  { title: "Lilla", place: "KÖKI TERMÁL F5 Fitness (Vak Bottyán u. 75/A-C)", name: "", pic: Lilla }
];

const locations = [
  { address: "Petneházy u. 81. 1. emelet", imageUrl: Petnehazy },
  { address: "Telepes utca 51 - Táncterem Zugló", imageUrl: "https://www.tancteremzuglo.hu/wp-content/uploads/2022/03/ta%CC%81ncterem-2-768x577.jpg" },
  { address: "Szépvölgyi út 44. La Place Studio", imageUrl: "https://static.wixstatic.com/media/2bb25b_77c8594978134c6bb20b96a72772afc3~mv2_d_6016_4016_s_4_2.jpg/v1/fill/w_2500,h_1668,al_c/2bb25b_77c8594978134c6bb20b96a72772afc3~mv2_d_6016_4016_s_4_2.jpg" },
  { address: "Dohány utca 5/b Astoria", imageUrl: "https://static.wixstatic.com/media/3e6277_1b504aaf2c9e408b9b1c4d5d1a800c46~mv2_d_4032_3024_s_4_2.jpg" },
  { address: "Ipar utca 3. Performance Studio", imageUrl: "https://www.performancestudio.hu/wp-content/uploads/2023/02/performance-studio-budapest-1-768x768.jpg" },
  { address: "Székesfehérvár, Széchenyi út 122.", imageUrl: Székesfehervar },
  { address: "KÖKI TERMÁL F5 Fitness (Vak Bottyán u. 75/A-C)", imageUrl: "https://fitness5.hu/wp-content/uploads/2024/06/F5_K_187_s.webp" }
];

const CardComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  const toggleModal = (location) => {
    const teachers = schedule.filter(item => item.place === location);
    setModalData(teachers);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div name="places" className="min-h-screen flex flex-wrap justify-center items-center bg-black p-10 gap-6">
      <h1 className="text-5xl text-white text-center w-full mb-6">Helyszínek</h1>
      {locations.map((location, index) => (
        <div
          key={index}
          className="relative w-72 h-48 cursor-pointer group border border-white bg-black rounded-lg"
          onClick={() => toggleModal(location.address)}
        >
          <img src={location.imageUrl} alt={location.address} className="w-full h-full object-cover rounded-lg" />
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-60 rounded-lg">
            <h3 className="text-lg font-semibold text-center">{location.address}</h3>
          </div>
        </div>
      ))}
      {isModalOpen && modalData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
          <div className="bg-black p-6 rounded-lg shadow-lg text-center max-w-md w-full">
            <h2 className="text-2xl text-white font-bold mb-4">Tanárok</h2>
            {modalData.length > 0 ? (
              <div className="flex overflow-x-auto space-x-4 p-4">
                {modalData.map((teacher, idx) => (
                  <div key={idx} className="flex flex-col items-center flex-shrink-1 w-40">
                    <img src={teacher.pic} alt={teacher.title} className="" />
                    <p className="text-lg text-white font-semibold mt-2">{teacher.title}</p>
                    <p className="text-red-600">{teacher.name}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-red-600">Nincs elérhető tanár ezen a helyszínen.</p>
            )}
            <button onClick={() => setIsModalOpen(false)} className="mt-4 px-4 py-2 bg-red-800 text-white rounded hover:bg-red-600 transition">
              Bezár
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardComponent;

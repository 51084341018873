import Open from '../assets/open.jpg'

const Header = () => {
    return (
        <div name="header" className='bg-black w-full h-full bg-center  '>
            
            <img className="object-cover h-full w-full" src={Open} alt="us" />
             
            
        </div>
    )
}

export default Header

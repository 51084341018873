const data = {
    /* HUNGARIAN*/
 hu:{
    nav1:'Rólunk',
    nav2: 'Tanárok',
    nav3: 'Képzések',
    nav4: 'Órarend',
    nav5: 'Események',
    nav6: 'Openweek',
    nav7: 'Kapcsolat',
    nav8: 'Helyszínek',

    about1:'Rólunk',
    about2: `Lakatos Loretta alapította 2017-ben a Hot Girly Dance Classt.
    Jelenleg Magyarország egyedülálló női közössége, ahol a magassarkú tànc nagyköveteként érvényesíti a tànc iránt érzett szenvedélyét,megteremtve ezzel annak a lehetőségét,hogy minél több nőtársa élhesse meg óráin új minőségben nőiességét. A HotGirly nem pusztán a táncról, vagy különböző koreográfiák elsajátításáról szól. Ezen eszközök felhasználásával nyitja meg a kaput egy olyan világ ajtaján, ahol a gátlásokat és a társadalmi előítéleteket maga mögött hagyva minden nő képes lesz újra megélni nőiességét. A HGDC egy szenvedély, ami képes kibontani a mélyen rejlő önbizalmat, ami megerősít egy csalódás után, ahol újra Nőnek érezheted magad! A HOT GIRLY az a testi kifejezése a lélek megerősödésének! Engedd, hogy magával ragadjon a HOT életérzés és váljunk együtt azzá akivé lenni akarunk!`,

    events1:'Események',
    events2:'Aktuális és közelgő események',
    events3: 'Tábor',
    events4: 'HOT GIRLY GÁLA',
    events5: 'Nyílt hét',

    Schedule1: 'Petneházy u. 90. 1. emelet',
    Schedule2: 'Telepes utca 51 - Táncterem Zugló',
    Schedule3: 'Szépvölgyi út 44. La Place Studio',
    Schedule4: 'Dunakeszi, Fóti út 120. Dream Factory Fitness /Tesco/',

    training1:`bővebb információt találsz a képzésekre kattintva`,
    kezdo:`Kezdő`,
    közephalado:`Középhaladó`,
    halado:`Haladó`,
    open1:`Open`,
   basic: `A különböző hangulatú koreográfiák mellett szàmos alaptechnikai feladat is be van építve a tanfolyami órák felépítésébe
    /alsótest-,felsőtest technikák,talajtechnikák&forgások,walk/
    Ez a képzés barmilyen táncos múlttal rendelkező kezdőknek illetve a közèphaladóknak van kitalálva.`,
   startup: `BÁRKI ÚJRA tudja kezdeni a 0-ról,vagy vissza tud térni hosszú idő után illetve új erőre kapva tudja folytatni. Rengetegen írtok egész évben, hogy teljesen kezdőként csatlakozhattok-e!!! NA EZ A KURZUS ITT MOST PONTOSAN EMIATT JÖTT LÉTRE
   Kiknek ajánlom?
   AKIK MÉG SOSEM TÀNCOLTAK
   AKIK NEM TÀNCOLTAK MÉG MAGASSARKÚBAN`,
   startup1:`Kiknek ajánlom?`,
   startup2:`AKIK MÉG SOSEM TÀNCOLTAK`,
   startup3:`AKIK NEM TÀNCOLTAK MÉG MAGASSARKÚBAN`,
   satratup4:`AKIKNEK van valamennyi táncos múltjuk,de mondjuk 10 évvel ezelőttről
   sok-sok alapozó technikával készülök Nektek
   könnyedebb koreográfiák`,
   motivated:`Koreográfiák mellett kiemelt szerepet kapnak a továbbiak: arckifejezés,attitűd; videos viselkedés; skillek felfedezése
   Ez a kurzus HALADÓ SZINTŰ,ami a fellépő csoport képzését szolgálja.`,
   open:`Az open táncórák lehetőséget kínálnak mindenki számára, hogy bármikor csatlakozhasson, függetlenül a tánctudás szintjétől.`,

   teachers:'Bővebben',
   teachers1:`7 éves koromban találkoztam először a tánc műfajával. Akrobatikus rock and roll-ban értem el hazai és nemzetközi sikereket;majd 16 éves koromban ismerkedtem meg a hip-hop stílussal. Fiatal korom ellenére koreográfusként kaptam lehetőséget több egyesületnél,de emellett táncosként folyamatosan fejlesztettem magam.
   Showtáncosként külföldi színpadon is dolgoztam (Silja Serenade,Silja Galaxy),majd hazatérve magyar tehetségkutató műsorok(X-Faktor,Star Academy,Sztárban Sztár),showműsorok(Allee Fashion Show,Szegedi Árkád Show,Wonderland Show Dance Company) illetve számos videoklip/koncert (Wellhello,Majka,BSW,MissMood,Metzker Viktória,Szakàcs Gergő, Tolvai Reni) szereplőjeként láthatnak a mai napig. 
   2017-ben tartottam első magassarkús tàncórámat,ami nagyon gyorsan népszerűvé vált. Egy csoportból hirtelen 3 lett,aztán jöttek a tanárok,plusz helyszínek,s elterjedt a Hot Girly országszerte.
   Emellett folyamatosan jöttek a felkérések;jelenleg Horváth Tamás koreográfusa,a Demeter Richárd bemutatók koreográfusa,illetve számos rendezvény fűzödik még a nevemhez. `,
   teachers2:`Kis korom óta életem része a tánc, kicsiként fitnessel kezdtem, aztán szépen lassan elkezdtem kortárs-modern táncolni és ezzel párhuzamosan vágtam bele a hip-hop órákba is, majd 2017-ben a Dózsa György Gimnázium és Táncművészeti Szakgimnáziumba végeztem mint kortárs-modern táncos!

   A Hot Girly-be 2018ban kezdtem el járni, amikor elmentem az első nyílt napra én onnan tudtam, hogy nekem ide kell járnom és itt a helyem!
   Majd 2019-ben Lola felkért, hogy tudnám e képviselni az èn stílusommal a Hot Girlyt mint tanár.
   
   Számomra amit jelent, hogy igazán önmagam lehetek a táncban, kiadhatom a nőiességemet, segíthetek más Nőknek önbizalmat adni, amit nem csak a táncban tudnak hasznosítani, hanem a való életben is jobban elfogadják magukat; ha segíthetek nekik legalább ennyiben is már jobban érzem magam!
   Nekem a tánc jelenti az életet, ha az nem lenne én sem lennék, az hogy találtam egy ilyen helyet, ahol nőknek segíthetek, ahol támogatjuk egymást és ahol nincs olyan hogy rossz, ennél jobb dolog nincs is, szeretem amit csinálok! Rengeteget jelent nekem ez!! 
   És azóta is köszönöm és örülök, hogy ilyen csapattal dolgozhatok együtt, a családommá váltak.
   
   Amikben Szerepeltem: 
   TV Showk /X-faktor, Álarcos énekes, Sztárban sztár, MTVA hàttértàncos/
   Videoklipek /Kali, Horvath Tamás, Wellhello,  
   Dj Metzker Viktória és Miss Mood, Kowalsky meg a Vega, T.Danny & Kkevin & DrBRS, Tiszai Vivien/
   Különböző rendezvények /Allee show, Demeter Richard fashion show, Wonderland show company táncos, Rossmann reklám, Augusztus 20. Ünnepség felvonulás, Tesla musical/
   Koncertek /Kali koncert turné, Horvath Tamás koncert háttértáncos/`,
   teachers3:`“Hat évesen vettem részt az első hip-hop órámon,de valójában már előtte órákat képes voltam végig ugrálni a kedvenc kazettámra. Először otthon, egy szegedi tánciskolában kezdtem el  komolyabban foglalkozni a témával, ami szinte egyből a versenyzéshez vezetett. Több évig jártam az országos versenyek fordulóit formációban, kiscsoportban, duóban, és szóló kategóriában. Rengeteg éremmel és győzelemmel lettem gazdagabb. Később  Szegeden kezdtem el tanítani pici gyerekeket, akik az évek során velem együtt felnőttek. Sokáig kerestem azt a stílust, amiben igazán megtalálom Önmagam, és teljesen azonosulni tudok vele. Így találtam rá a Hotgirlyre. Először magán órákat vettem, hiszen folyamatosan ingáztam a két város között.
   Szerencsére egy évvel később felköltöztem, és oszlopos tagja lettem a haladó tanfolyamnak. Egyre jobban kezdtem megérteni saját magam. Rátaláltam a hangomra. Ma már teljesen biztos vagyok abban, mi az amit szívesen képviselek. Folyamatosan fejlesztem magam ,és igyekszem sok féle stílust kipróbálni, legyen ez balett, jazz, vagy pedig oldschool hiphop. 
   Volt szerencsém táncosként tevékenykedni Tv műsorokban (X faktor, Sztárban Sztár), videó klipekben (Meztker Viki, Horváth Tamás, Spigi Boy,Cserpes Laura), különböző rendezvényeken (Demeter Richárd Fashion Show, Mirror Budapest), koncerteken (Kali Ale Ne Tour, Efott, SZIN). 2020 októberétől pedig hivatalosan is a hotgirlydanceclass tanári csapatát erősíthetem.
   Az én órámon nem csak egy tánc órán veszel részt, nagy hangsúlyt fektetünk Rád! A belső hangra,amit a mindennapokban hajlamosak vagyunk elnyomni. A mozdulatok, és a különböző hangulatú táncok segítenek neked megismerni Önmagad, és olyan magabiztosságot fogsz kapni, amit a hétköznapi életben tudsz hasznosítani. 
   Megélünk minden jót, rosszat,olyan érzést,amit nehéz kezelni. 
   Ha úgy érzed, hogy valamiért mindig kilógsz a sorból, és “különc” vagy, akkor nálam a helyed.
   Jómagam,állandó  “vidéki”  kívülállóként bebizonyítom neked, hogy Másnak lenni jó, és megmutatom, a tánc az lehetsz aki lenni szeretnél.“`,
   teachers4:`Mióta az eszemet tudom táncolok,anyukám szerint előbb kezdtem el ritmusra mozogni mint járni..:)
   6éves koromban kipróbáltam a Jazz balettet, 9éves koromban pedig elkezdtem a Hip Hop ot. Első lépésre szerelem volt:) 
   Mikor táncolok megszűnik minden. Csak én és a zene. Akkor érzem igazán,hogy élek. A mindenséget jelenti. Mosolyt csal,megsirat,erőt ad. 
   Jártam különböző versenyekre,pár éremmel és kupával lettem gazdagabb na meg tapasztalattal:)
   Évekkel később Jött egy pont az életemben amikor kicsit elveszettnek éreztem magam a tánc világában,nem igazán találtam a helyem. Aztán Facebook-on láttam egy hirdetést..Hot Girly Dance Class nyílt nap....SOHA nem fogom elfelejteni az első órát. Újra rázott a hideg a boldogságtól és csordultig voltam motivációval. Ki tudtam fejezni a nőiességemet úgy,hogy közben vagány zenére,hip hop-ot táncolhattam. Magassarkúban. Rátaláltam önmagamra és ezért mindig hálás leszek. 
   2020 Októberétől tanítok a HotGirly-ben…ami maga a csoda! A közösség,a tanárok és a Mi BossLadynk. 
   
   Volt/van szerencsém megtapasztalni azt is milyen is a táncosok élete:
   
   Videoklipek: 
   WellHello,Dj Metzker Viki,Horváth Tamás, LL.Junior, Weyron, SpigiBoy
   Tv műsorok:
   X-faktor ,Álarcos énekes
   Koncertek: Kali-Ale ne Tour, SZIN,Effot
   Rendezvényeken: 
   Mirror, Demeter Richard fashion show
   
   Minden egyes színpadra lépés egy adrenalin löket amit imádok.
   Az óráimon ugyanezt tapasztalhatjátok meg. Napról napra azon vagyok,hogy átadjam Nektek a Tánc szeretetét.
   Sokan mondják,hogy nem csak táncórára jönnek,hanem egy lelki feltöltődésre is. Nevetünk,sírunk,beszélgetünk,csapatot építünk,figyelünk egy kicsit belülre is és egymásra. Igyekszem kihozni belőletek a NŐT,hogy magabiztosan érezzétek magatokat akkor is mikor rossz napotok van vagy éppen negatív hullámba kerül az életetek. Zene be világ ki! 
   Amikor táncolunk akkor élünk igazán.
   `,
   teachers5:`Igyekszem mindig új arcomat mutatni, más - más hangulatokkal. Legyen az akár lassú érzelmes, vagy vagány csajos, határ a csillagos ég, együtt megélünk majd mindent.
   Félnivalód ne legyen semmitől, az alapoktól kezdünk el építkezni, és amiben csak tudok a segítségedre leszek.
   Célom, hogy átadjam Neked a HotGirly életérzést, fejleszteni tudjuk az önbizalmadat, és emellett ÉLVEZD amit csinálsz, járjon át Téged a tánc szeretete! Hagyjuk kint a külvilágot, és éld meg Magadat, a benned rejlő IGAZI NŐT, amit természetesen magaddal tudsz vinni a mindennapokba.
   
   Magamról röviden:
   8 éves korom óta életem része a tánc, mára viszont már az életem!
   Az évek során volt lehetőségem mindenféle táncstílusban kipróbálni magamat, mint például hip-hop, kortárs, modern, balett.
   2017-ben a Dózsa György Gimnázium tánc szakán kortárs-modern táncművészként végeztem, ez után pedig elkezdtem kisgyerekeknek hip-hopot tanítani. Már ezalatt is, szinte a megalakulástól kezdve követtem a Hot Girly tevékenykedését, mindig sóvárogva néztem Őket, mikor vettem a bátorságot, és 2018-ban elmentem az első órámra. Szerelem volt, ami azóta töretlenül tart.
   Mint minden Nőnek, nekem is akadnak problémáim az önbizalmammal, nőiességemmel kapcsolatban, és ez volt a tökéletes út a fejlődéshez.
   Volt szerencsém táncosként tevékenykedni Tv műsorokban (Sztárban Sztár, Álarcos Énekes, Tehetség első látásra, Csináljuk a fesztivált) videó klipekben (Wellhello x Valmar, DR BRS x KKevin x T.Danny, BSW), különböző rendezvényeken (Demeter Richárd Fashion Show), koncerteken (Wellhello, Horváth Tomi, Kali Ale Ne Tour).
   És ami számomra még mindig hihetetlen, hogy mostmár a HOT Girly tanári csapatában lehetek.`,
   teachers6:`

   Fontosnak tartom, hogy óráimon változatos hangulatú és stílusú táncok legyenek, amelyek által a bennünk lévő érzéseket
   /boldogságot, szomorúságot és feszültséget/ meg tudjuk élni/ kitudjuk adni magunkból. 
   Az órákon lehetőséged van megtanulni a magassarkús tánc rejtelmeit az alapoktól, így bátran csatlakozhatsz, ha kezdő szintű is vagy.
   Szeretném fejleszteni óràimon a magabiztosságot, az önbizalmat, hogy a koreográfiákon keresztül kiteljesedhessen a Benned rejlő Nő!
   
   
   9 éve az életem része a tánc, amelyből már lassan 4 és fél éve tartozhatok a Hot Girly közösséghez. A hozzám legközelebb álló stílusok a Girly és a Jazz funk, de ezek mellett a többi stílusban is szeretem megmutatni magam.
   Különböző workshopok keretein belül fejlesztem magam folyamatosan; számos külföldi táncostól tanultam eddig mint pl. Aliya Janell, JoJo Gomez, Todd Williamson, Melissa Barlow, Alexis Beaulexx, Jake Kodish stb.
   Profi táncosként részt vettem koncerteken (Azahriah, Desh, Dzsúdló), 
   videóklipekben (BSW, ValMar, Wellhello, DR BRS x K.Kevin x T.Danny, Horváth Tomi) ,
   TV műsorokban (Sztárban Sztár, Álarcos énekes, Sztárbox, Csináljuk a fesztivált),  különböző rendezvényeken ( Demeter Richárd Fashion show, EHF, FINA, Superenduro, Darts világbajnokság, Superfight series, Drk Store megnyitó).
   Mindezek mellett, már második éve dolgozom együtt Szikora Robival és az R-go-val, mint “Gida”.
   Nagyon hálás vagyok, hogy idén már én is erősíthetem a Hot Girly tanárikarát. `,
   teachers7:`

   Ez egy csodálatos történet kezdete! Teljesen kezdőként is bátran csatlakozhatsz, de akkor is szeretettel várlak, ha már van táncos múltad.

      *Mit kínálok neked?*
      - Az alapgyakorlatok és technikák elsajátítása, hogy stabil alapokra építkezhess.
      - Fókusz a magabiztosságra, nőiességre, kecsességre és vadságra, hogy önmagad legjobb verziójává válj.
      - Változatos hangulatú koreográfiák, amelyek minden alkalommal új kihívásokat hoznak.`,


   contact1:'Kapcsolat',
   contact2:'Cím',
   contact3:'Petneházy u. 90. 1. emelet',
   contact4:'Küldés',

   question:'Gyakori kérdések',
   question1:'Hány éves kortól lehet csatlakozni az órákhoz?',
   question2:'Milyen cipőt hozzak?',
   answer2:'Olyan magassarkút,ami számodra kényelmes és stabil,ami fogja a bokát',
   question3:'Milyen ruhàt kell magammal vinni?',
   answer3:'Ami számodra kényelmes. Amiben látszódnak a vonalaid és magabiztosságot ad Neked.',
   question4:'Szükséges-e regisztráció/bejelentkezés?',
   answer4:'Nem szükséges az órákra regisztrálni;csak a  6 hetes képzéseinkre.',
   question5:'Mit kell mèg magammal vinnem?',
   answer5:'Váltócipőt (magassarkút és tornacipőt), vastagabb zoknit, térdvédőt a földes koreográfiàkhoz, vizet.',
   question6:'Hogyan tudok fizetni?',
   answer6:'Az óra előtt készpènzben, esetleg Revoluton utalással',
   question7:'Teljesen kezdőknek melyik órát ajánljátok?',
   answer7:`  Lakatos Loretta
        Kedd- Csütörtök
    19:00-20:00 HOT START UP kurzus
    Helyszín: 1139 Budapest, Petneházy u. 90. 
    HOT STUDIO 1. emelet`,
    question8:'Árak',
    answer8:`Óradíj: 3.000 Ft `,
    answer8a:`90 perces óra 4.000 Ft`,
    answer8b:`6hetes képzések: Basic 20.000 Ft`,
    answer8c:` Stage 25.000 Ft.`,



    footer1:'Engedd, hogy magával ragadjon a HOT életérzés és váljunk együtt azzá akivé lenni akarunk!',
    footer2:'Gyakori kérdések',
    footer3:'Adatkezelési Tájékoztató',
    footer4:'Minden jog fenntartva',

    testimonail1:'Van az a pont,mikor hiányzik az ember életéből valami, az egyik az fixen a te órád!',
    testimonial2:'Azért,mert azt éreztem ez egy fontos lépés, amit tenni tudok magamért, a nőiességemért, az egészséges énképemért, és ezt maximálisan megtaláltam nálad. Amikor először voltam nálad akkor még csak a kiváncsiság hajtott,de azóta a táncórák lettek számomra az edzések, a meditációk, a terápiák és az én idők egyben ❤️',
    testimonial3:'... nem is volt más gondolat a fejemben csak hogy nekem ezt KI KELL PRÓBÁLNOM!!! Lementem az első órára és ami akkor történt velem az valami hihetelen volt, amellyett hogy iszonyú magabiztosnak és nőisnek éreztem magam(és érezem is minden órádon), amellett nagyon kemény edzés is, amire nekem pont szükségem volt. Aztán teltek az óák, egyre jobban "otthon" éreztem magam, azt éreztem, hogy befogadnak a LÁNYOK, hogy részem van ebben a csapatban. Idővel barátságokat kötöttem és ezt mind NEKED és a HOT-nak köszönhetem és köszönöm is!!!❤️',
    testimonail4:'... az a hatalmas pozitív energia és vidámság az, ami nagyon megfogott és úgy gondoltam, hogy nekem erre szükésgem van ❤️...még inkább bele tudom tenni magamat testileg és lelkileg a Hot girlybe 💖💖💖 szóval nagyon köszönöm amit eddig adtál!',
    testimonail5:'Nagyon szeretnék részese lenni ennek a csodának,amit megálmodtál! Minél többet szeretnék fejlődni és tanulni tőled. Örülök, hogy rátaláltam az óráidra, fénypontot hoznak a mindennapokba❣️',


    privacy1:'Tájékoztatás a sütik használatáról',
    privacy2: 'A személyes adatok feldolgozásának terjedelme',
    privacy3: `A különféle oldalakon sütiket alkalmazunk, hogy webhelyünk bizonyos funkcióinak felhasználását lehetővé tegyük. Az ún. „sütik” esetében kis szövegfájlokról van szó, melyeket az Ön böngészője a számítógépére elmenthet. A szövegfájlok egy bizonyos karaktersorrendet tartalmaznak, mely a böngésző egyértelmű azonosítását teszi lehetővé webhelyünk ismételt előhívásakor. Egy süti fájl elhelyezésének eljárását „sütielhelyezésnek” is nevezik.
    A személyes adatok feldolgozása
     A weboldal semmilyen személyes adatot nem tárol.
     Amennyiben az adatkezelő szolgáltatásaink igénybevétele során kérdés merülne fel, esetleg problémája lenne az érintettnek, a honlapon megadott módokon (telefon, e-mail, közösségi oldalak stb.) kapcsolatba léphet az adatkezelővel.
    Adatkezelő a beérkezett e-maileket, üzeneteket, telefonon, Facebook-on stb. megadott adatokat az érdeklődő nevével és e-mail címével, valamint más, önként megadott személyes adatával együtt, az adatközléstől számított legfeljebb 2 év elteltével törli.
    Kivételes hatósági megkeresésre, illetőleg jogszabály felhatalmazása alapján más szervek megkeresése esetén a Szolgáltató köteles tájékoztatás adására, adatok közlésére, átadására, illetőleg iratok rendelkezésre bocsátására.`,
    privacy4: 'Az adatfeldolgozás célja',
    privacy5: 'A sütiket webhelyünk azért helyezi el, hogy weboldalunk teljes működőképességét fenntartsa, és a kezelhetőséget javítsa. Emellett a sütis technológia lehetővé teszi egy látogatók számára, hogy pl. egyes egyedi, tetszőleges azonosítókat ismét felismerjen, annak érdekében, hogy lehetséges legyen számunkra testre szabottabb szolgáltatásokat nyújtani',
    privacy6: 'A tárolás időtartama',
    privacy7: 'Sütijeink tárolása addig történik, míg törlésre kerülnek böngészőjéből, vagy ha egy munkamenet sütiről van szó, a munkamenet végéig.',
    privacy8: 'Elutasítási és kizárási lehetőségek',
    privacy9: `Böngészőjét saját kívánságainak megfelelően állíthatja be úgy, hogy a sütik elhelyezését általában letiltja, vagy az elhelyezésükről tájékoztatást kapjon, és esetenként dönthessen azok elfogadásáról, vagy alapvetően elfogadja a sütik elhelyezését. A sütiket különféle célokra lehet alkalmazni, pl. annak felismerésére, hogy számítógépe egyszer már kapcsolatban volt egy webes ajánlatunkkal (tartós sütik) vagy legutóbb meglátogatott ajánlatok elmentésére (Tanácskozási sütik). Sütiket használunk, hogy nagyobb felhasználói kényelmet nyújthassunk. Kényelmi funkcióink használatához javasoljuk a sütik elfogadását webes kínálatunkhoz.
    Az elutasítási és kizárási lehetőségekre a jelen Adatvédelmi nyilatkozatban a következőkben bemutatott általános szabályozások vonatkoznak az adatvédelmi jogi elutasítási és kizárás igények tekintetében.`





   



 },
 
 /* ENGLISH */
 en:{
    nav1:'About',
    nav2: 'Teachers',
    nav3: 'Trainings',
    nav4: 'Schedule',
    nav5: 'Events',
    nav6: 'openweek',
    nav7: 'Contact',  
    nav8: 'Places',

   about1:'About',
   about2:`Loretta Lakatos founded the Hot Girly Dance Class in 2017.
   Currently, it is Hungary's unique women's community where, as an ambassador of high-heel dance, she asserts her passion for dance, thus creating the opportunity for as many of her fellow women as possible to experience femininity in a new quality during her classes. HotGirly is not just about dancing or learning different choreographies. Using these tools, she opens the door to a world where every woman will be able to experience her femininity again, leaving behind inhibitions and social backgrounds. HGDC is a passion that can unlock the deep-seated confidence that strengthens you after a disappointment where you feel like a Woman again! HOT GIRLY is the physical expression of the strengthening of the soul! Let yourself be carried away by the HOT feeling of life and let's become who we want to be together!`,  

   events1:'Events',
   events2: 'Current and upcoming events',
   events3: 'Camp',
   events4: 'HOT GIRLY GALA',
   events5: 'Open week',

   Schedule1: 'Petneházy street 90. 1. floor',
   Schedule2: 'Telepes Street 51 - Zugló Dance Hall',
   Schedule3: 'Szépvölgyi street 44. La Place Studio',
   Schedule4: 'Dunakeszi, Fóti road 120. Dream Factory Fitness /Tesco/',

   training1:`you can find more information by clicking on the trainings`,
   kezdo:`Beginner`,
   közephalado:`Intermediate`,
   halado:`Advanced`,
   open1:`Open`,
   basic: `In addition to choreographies with different moods, a number of basic technical tasks are also included in the structure of the course lessons/lower body, upper body techniques, floor techniques & rotations, walk/This course is absolutely designed for beginners and intermediates.`,
   startup: `ANYONE can start AGAIN from 0, or can return after a long time or continue with new strength. Many of you have written throughout the year asking if you can join as a complete beginner!!! THIS COURSE WAS CREATED EXACTLY FOR THIS REASON
      Who do I recommend it to?
      THOSE WHO HAVE NEVER DANCED
      THOSE WHO HAVE NEVER DANCED IN HIGH HEELS`,
   motivated:`In addition to choreography, the following are given a prominent role: facial expression, attitude; video behavior; skills discovery
   This course is ADVANCED LEVEL, which serves to train the performing group.`,
   open:`Open dance classes offer the opportunity for everyone to join at any time, regardless of their level of dance ability.`,

   teachers:'See More',
   teachers1:`I first encountered the genre of dance when I was 7 years old. I achieved domestic and international success in acrobatic rock and roll; then, when I was 16, I got to know the hip-hop style. Despite my young age, I was given the opportunity to work as a choreographer in several associations, but in addition I constantly developed myself as a dancer.
   As a show dancer, I also worked on foreign stages (Silja Serenade, Silja Galaxy), and after returning home Hungarian talent shows (X-Faktor, Star Academy, Sztárban Sztár), shows (Allee Fashion Show, Szegedi Árkád Show, Wonderland Show Dance Company) and numerous video clips/concerts (Wellhello, Majka, BSW, MissMood, Viktória Metzker, Gergő Szakàcs, Reni Tolvai) can still be seen today.
   In 2017, I held my first high heel dance class, which quickly became popular. One group suddenly became 3, then the teachers came, plus locations, and Hot Girly spread all over the country.
   In addition, the invitations kept coming; I am currently the choreographer of Tamás Horváth, the choreographer of the Demeter Richárd shows, and many other events.`,
   teachers2: `Dance has been a part of my life since I was little, I started with fitness when I was little, then slowly I started contemporary-modern dancing and at the same time I took hip-hop classes, and in 2017 I graduated from György Dózsa High School and Dance Arts Vocational High School as a contemporary-modern dancer!

   I started going to Hot Girly in 2018, when I went to the first open day, I knew from there that I had to go here and that this was my place!
   Then in 2019, Lola asked me if I could represent Hot Girly as a teacher with my style.
   
   For me, what it means is that I can really be myself in dance, I can express my femininity, I can help give other women self-confidence, which they can use not only in dance, but also in real life; if I can help them in at least that much, I feel better!
   For me, dance means life, if it weren't for it I wouldn't be either, because I found such a place where I can help women, where we support each other and where there is no such thing as bad, there is nothing better, I love what I do! This means a lot to me!!
   And since then, I thank you and I'm happy that I can work with such a team they have become my family
   
   In which I acted:
   TV Shows /X-factor, Masked singer, Star in a star, MTVA backup dancer/
   Video clips /Kali, Tamás Horvath, Wellhello,
   Dj Metzker Viktória and Miss Mood, Kowalsky and Vega, T.Danny & Kkevin & DrBRS, Tiszai Vivien/
   Various events /Allee show, Demeter Richard fashion show, Wonderland show company dancer, Rossmann commercial, August 20th celebration parade, Tesla musical/
   Concerts /Kali concert tour, Tamás Horvath concert background dancer/`,
   teachers3:`"I took my first hip-hop class when I was six years old, but actually I could jump to my favorite tape for hours before that. I first started to take the subject more seriously at home, in a dance school in Szeged, which almost immediately led to competition. For several years, I participated in the rounds of national competitions in formation, small group, duo and solo categories. I became richer with many medals and victories. Later, I started teaching small children in Szeged, who grew up with me over the years. For a long time, I searched for the style in which I can really find myself and can completely identify with it. That's how I found Hotgirly. At first I took private lessons, as I was constantly commuting between the two cities.
   Fortunately, a year later I moved up and became a regular member of the advanced course. I began to understand myself more and more. I found my voice. Today, I am completely sure of what I would like to represent. ❤️ I am constantly improving myself and I try to try many different styles, be it ballet, jazz, or old-school hip-hop.
   I was lucky enough to act as a dancer in TV programs (X factor, Sztárban Sztár), video clips (Viki Meztker, Tamás Horváth, Spigi Boy, Cserpes Laura), at various events (Demeter Richárd Fashion Show, Mirror Budapest), concerts (Kali Ale Ne Tour, Efott , COLOR). And from October 2020, I can officially strengthen the teaching team of @hotgirlydanceclass
   In my class, you don't just take part in a dance class, we put a lot of emphasis on you! To the inner voice, which we tend to suppress in everyday life. The movements and dances with different moods will help you get to know yourself, and you will gain a confidence that you can use in everyday life.
   We experience all the good and bad, feelings that are difficult to handle.
   If you feel that for some reason you always stand out of line and are an "eccentric", then you belong with me  As a permanent "rural" outsider, I will prove to you that it is good to be different, and I will show you that dance can be who you want to be.  “`,
   teachers4:`I've been dancing since I can remember, according to my mom, I started moving to the rhythm before walking...:)
   I tried Jazz ballet when I was 6 years old, and I started Hip Hop when I was 9 years old. It was love at first sight :)
   When I dance, everything stops. Just me and the music. That's when I really feel alive. It means everything. It makes you smile, makes you cry, gives you strength.
   I went to different competitions, got a few medals and cups, and gained more experience :)
   Years later, there came a point in my life when I felt a little lost in the world of dance, I couldn't really find my place. Then I saw an ad on Facebook..Hot Girly Dance Class open house....I will NEVER forget the first class. I was shaking with happiness again and I was overflowing with motivation. I was able to express my femininity by dancing hip hop to cool music. In high heels. I found myself and for that I will always be grateful.
   From October 2020 I teach at HotGirly...which is a miracle! The community, the teachers and Our Boss Lady.
   
   I was/am lucky enough to experience what life is like for dancers:
   
   Video clips:
   WellHello, DJ Viki Metzker, Tamás Horváth, LL.Junior, Weyron, SpigiBoy
   TV programmes:
   X-factor, Masked singer
   Concerts: Kali-Ale ne Tour, SZIN, Effot
   At events:
   Mirror, Demeter Richard fashion show
   
   Every step on stage is an adrenaline rush, which I love.
   You can experience the same in my classes. Every day I am trying to pass on the love of dance to you.
   Many people say that they come not only for a dance lesson, but also for a spiritual recharge. We laugh, we cry, we talk, we build a team, we pay attention to ourselves and to each other. I try to bring out the WOMAN in you, so that you feel confident even when you are having a bad day or your life is in a negative wave. Music in, World out!
   When we dance, we really live`,
   teachers5:`I always try to show my new face, with different moods. Whether it's slow emotional or cool girly, the starry sky is the limit, we'll experience everything together💫
   Don't be afraid of anything, we'll start building from the ground up, and I'll help you in whatever way I can.
   My goal is to give you the HotGirly feeling of life, we can develop your self-confidence, and in addition ENJOY what you do, let the love of dance pass through you! Let's leave the outside world outside and live yourself, the REAL WOMAN inside you , which you can of course take with you into everyday life.
   
   Briefly about myself:
   Dance has been part of my life since I was 8 years old, but now it's my life!✨
   Over the years, I had the opportunity to try myself in all kinds of dance styles, such as hip-hop, contemporary, modern, ballet.
   In 2017, I graduated from György Dózsa High School's dance department as a contemporary-modern dancer, and after that I started teaching hip-hop to young children. Even during that time, almost from the beginning, I followed the activities of Hot Girly, I always watched them longingly, when I took the courage and went to my first class in 2018. It was love that has kept me unbroken ever since ❤️
   Like all women, I have problems with my self-confidence and my femininity, and this was the perfect way to improve
   I was lucky enough to act as a dancer in TV shows (Star in a Star, Masked Singer, Talent at first sight, Let's do the festival) in video clips (Wellhello x Valmar, DR BRS x KKevin x T.Danny, BSW), at various events (Demeter Richárd Fashion Show), concerts (Wellhello, Tomi Horváth, Kali Ale Ne Tour).
   And what is still unbelievable to me is that I can now be in the teaching team of HOT Girly😍`,
   teachers6:`I consider it important that my classes have dances with a variety of moods and styles, through which we can express the feelings within us.
/happiness, sadness and tension/ we can experience/ let it out.
In the classes, you have the opportunity to learn the secrets of high heel dance from the basics, so you can join with confidence even if you are a beginner.
I would like to develop self-confidence and self-confidence in my classes, so that the Woman in You can be fulfilled through the choreographies!
Dance has been a part of my life for 9 years, of which I have been part of the Hot Girly community for almost 4 and a half years. The styles closest to me are Girly and Jazz funk, but I also like to show myself in other styles.
   I am constantly developing myself within the framework of various workshops; I have learned so far from many foreign dancers, e.g. Aliya Janell, JoJo Gomez, Todd Williamson, Melissa Barlow, Alexis Beaulexx, Jake Kodish, etc.
   I participated in concerts as a professional dancer (Azahriah, Desh, Dzúdló),
   in video clips (BSW, ValMar, Wellhello, DR BRS x K.Kevin x T.Danny, Tomi Horváth),
   In TV programs (Sztárban Sztár, Masked singer, Sztárbox, Let's do the festival), at various events (Demeter Richárd Fashion show, EHF, FINA, Superenduro, World Darts Championship, Superfight series, Drk Store opening).
   In addition to all this, I have been working with Rob Szikora and R-go for the second year as "Gida".
   I am very grateful that this year I can also strengthen the teaching staff of Hot Girly.`,

   teachers7:`This is the beginning of a wonderful story! You can join as a complete beginner, but I also welcome you if you already have a dance background.

*What do I offer you?*
- Mastering the basic exercises and techniques so that you can build on a solid foundation.
- Focus on confidence, femininity, grace and fierceness to become the best version of yourself.
- Choreographies with a varied atmosphere that bring new challenges every time.`,

   contact1:'Contact',
   contact2:'Adress',
   contact3:'Petneházy street 90. 1. floor',
   contact4:'Send',

   question:'Frequently Asked Questions',
   question1:'At what age can you join the classes?',
   answer1:'16+',
   question2:'What shoes should I bring?',
   answer2:'High heels that are comfortable and stable for you, that support your ankles',
   question3:'What clothes should I bring?',
   answer3:'Whatever is convenient for you. In which your lines are visible and gives you confidence.',
   question4:'May I have to registration?',
   answer4:'It is not necessary to register for the classes; only for our 6-week courses.',
   question5:'What else do I need to take with me?',
   answer5:'Change shoes (high heels and sneakers), thicker socks, knee pads for earthy choreography, water.',
   question6:'How can I pay',
   answer6:'Before class, in cash, or possibly with a Revoluton transfer.',
   question7:'Which class do you recommend for complete beginners?',
   answer7:`  Lakatos Loretta
   Tuesday-Thursday
19:00-20:00 HOT START UP course
  Place: 1139 Budapest, Petneházy street 90. 
    HOT STUDIO 1. floor`,
    question8:'Prices',
    answer8:`hourly fee: 3.000 Ft`,
    answer8a:`90-minute lesson HUF 4,000`,
    answer8b:` 6 weeks course: Basic 20.000 Ft`,
    answer8c:`Stage 25.000 Ft.`,

    footer1:`Let yourself be carried away by the HOT feeling of life and let's become who we want to be together!`,
    footer2:'Frequently asked Questions',
    footer3:'Privacy Policy',
    footer4:" All rights reserved.",
    
    testimonail1:`There is a point when something is missing in a person's life, one of which is definitely your watch!🙏🏼🙏🏼`,
    testimonial2:'Because I felt that this was an important step that I could take for myself, for my femininity, for my healthy self-image, and I found this to the fullest in you. When I was with you for the first time, I was only driven by curiosity, but since then the dance classes have become my training, meditations, therapies and my time together ❤️',
    testimonial3:'... there was no other thought in my head except that I MUST TRY this!!! I went to the first class and what happened to me then was something incredible, in that I felt extremely confident and feminine (and I feel it in every class), as well as very hard training, which I just needed. Then the years passed, I felt more and more "at home", I felt that the GIRLS accepted me, that I was a part of this team. Over time, I made friends and I can thank YOU and HOT for that and thank you!!!❤️',
    testimonail4:'... that enormous positive energy and cheerfulness is what really captured me and I thought that I needed this ❤️... I can put myself even more physically and mentally into Hot girly 💖💖💖 so thank you very much for what you have given me so far!',
    testimonail5:`I really want to be a part of this miracle you dreamed up! I want to grow and learn from you as much as possible. I'm glad I found your classes, they bring a highlight to everyday life❣️`,

    privacy1:'Information on the use of cookies',
    privacy2:'Scope of personal data processing',
    privacy3:`We use cookies on various pages to enable the use of certain functions of our website. The so-called "cookies" are small text files that your browser can save on your computer. The text files contain a certain sequence of characters, which enables the browser to be clearly identified when our website is called up again. The process of placing a cookie file is also called "cookie placement".
    Processing of personal data
      The website does not store any personal data.
      If a question arises when using our data management services, or if the data subject has a problem, you can contact the data manager using the methods provided on the website (telephone, e-mail, social media sites, etc.).
    The data controller processes received e-mails, messages, on the phone, on Facebook, etc. data provided, together with the name and e-mail address of the interested party, as well as other voluntarily provided personal data, will be deleted after a maximum of 2 years from the date of data communication.
    The Service Provider is obliged to provide information, communicate and transfer data, and make documents available in the event of an exceptional official inquiry, or in the event of an inquiry by other bodies based on the authorization of the law.`,
    privacy4:'Purpose of data processing',
    privacy5:'Our website places cookies in order to maintain the full functionality of our website and improve usability. In addition, cookie technology enables a visitor to e.g. recognize some unique, arbitrary identifiers again, so that it is possible to provide us with more customized services.',
    privacy6:'Duration of storage',
    privacy7:'Our cookies are stored until they are deleted from your browser or, in the case of a session cookie, until the end of the session.',
    privacy8:'Opt-out and opt-out options',
    privacy9: `You can set your browser according to your wishes so that you generally block the placement of cookies, or receive information about their placement, and in some cases decide to accept them, or basically accept the placement of cookies. Cookies can be used for various purposes, e.g. to recognize that your computer has already been connected to one of our web offers (persistent cookies) or to save the most recently visited offers (Consulting cookies). We use cookies to provide greater user comfort. To use our convenience functions, we recommend accepting cookies for our web offer.
    The rejection and exclusion options are subject to the general regulations presented below in this Privacy Statement regarding data protection legal rejection and exclusion requests.`,











 },
};
export default data;
import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Hot from '../assets/hot-girly.png';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import Language from '../components/LanguageBtn';

export default function NavBar({ data, i18n }) {
  const [nav, setNav] = useState(false);
  const menuRef = useRef(null);
  const menuIconRef = useRef(null);
  const location = useLocation();

  const handleClick = () => setNav(!nav);

  // Ha másik oldalról jövünk, automatikusan scrollozzon a megfelelő elemhez
  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      scroller.scrollTo(hash, {
        smooth: true,
        duration: 500,
        offset: -80,
      });
    }
  }, [location]);

  return (
    <div className="bg-black flex justify-between items-center h-20 mx-auto px-3 text-white">
      <div className="flex">
        <RouterLink to="/" className="m-2">
          <img src={Hot} alt="logo" style={{ width: '60px' }} />
        </RouterLink>
        <div className="m-7"></div>
        <Language i18n={i18n} />
      </div>

      <nav>
        {/* Desktop Menü */}
        <ul className="hidden md:flex">
          {[
            { id: 'about', label: data.nav1 },
            { id: 'teachers', label: data.nav2 },
            { id: 'calendar', label: data.nav4 },
            { id: 'trainings', label: data.nav3 },
            { id: 'places', label: data.nav8 },
            { id: 'contact', label: data.nav7 }
          ].map(({ id, label }) => (
            <li key={id} className="p-4 m-2 text-center">
              {location.pathname === "/" ? (
                <ScrollLink to={id} smooth={true} duration={500} offset={-80}>
                  {label}
                </ScrollLink>
              ) : (
                <RouterLink to={`/#${id}`}>
                  {label}
                </RouterLink>
              )}
            </li>
          ))}

          <li className="p-4 m-2 text-center">
            <RouterLink to="/events">
              {data.nav5}
            </RouterLink>
          </li>
        </ul>

        {/* Mobil Menü Gomb */}
        <div
          onClick={handleClick}
          ref={menuIconRef}
          className="md:hidden z-10 cursor-pointer"
        >
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        {/* Mobil Menü */}
        <ul
          ref={menuRef}
          className={`md:hidden ${
            !nav
              ? 'hidden'
              : 'absolute top-0 left-0 w-full h-screen bg-black flex flex-col justify-center items-center z-50'
          }`}
        >
          <button
            onClick={() => setNav(false)}
            className="absolute top-5 right-5 text-white text-2xl"
          >
            ✖
          </button>

          {[
            { id: 'about', label: data.nav1 },
            { id: 'teachers', label: data.nav2 },
            { id: 'calendar', label: data.nav4 },
            { id: 'trainings', label: data.nav3 },
            { id: 'places', label: data.nav8 },
            { id: 'contact', label: data.nav7 }
          ].map(({ id, label }) => (
            <li key={id} className="p-4 m-2 text-center">
              {location.pathname === "/" ? (
                <ScrollLink onClick={handleClick} to={id} smooth={true} duration={500} offset={-80}>
                  {label}
                </ScrollLink>
              ) : (
                <RouterLink onClick={handleClick} to={`/#${id}`}>
                  {label}
                </RouterLink>
              )}
            </li>
          ))}

          <li className="p-4 m-2 text-center">
            <RouterLink onClick={handleClick} to="/events">
              {data.nav5}
            </RouterLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
